import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const Seo = ({ pageTitle, pageDescription, pageUrl, logoUrl, keywords }) => {
  if (!HelmetProvider) {
    console.error('HelmetProvider is missing. Ensure it wraps your application.');
    return null;
  }

  return (
    <Helmet>
      <title>{pageTitle}</title>
      <meta name="description" content={pageDescription} />
      <meta
        name="keywords"
        content="achiziții publice, licitații, construcții, ListaLicitatii"
      />
      <meta name="robots" content="index, follow" />
      <meta name="keywords" content={keywords} />
      <link rel="canonical" href={pageUrl} />

      {/* Open Graph Meta Tags */}
      <meta property="og:title" content={pageTitle} />
      <meta property="og:description" content={pageDescription} />
      <meta property="og:url" content={pageUrl} />
      <meta property="og:image" content={logoUrl} />
      <meta property="og:type" content="website" />

      {/* Twitter Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={pageTitle} />
      <meta name="twitter:description" content={pageDescription} />
      <meta name="twitter:image" content={logoUrl} />
    </Helmet>
  );
};

export default Seo;
