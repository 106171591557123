import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/api/Account/log`;

// Funcție pentru extragerea unui cookie
const getCookie = (name) => {
  const cookies = document.cookie.split('; ');
  const cookie = cookies.find(row => row.startsWith(`${name}=`));
  return cookie ? cookie.split('=')[1] : null;
};

const RouteLogger = () => {
  const location = useLocation();

  // Generăm un traceId pentru sesiune
  const traceId = sessionStorage.getItem('traceId') || uuidv4();
  if (!sessionStorage.getItem('traceId')) {
    sessionStorage.setItem('traceId', traceId);
  }

  useEffect(() => {
    const logData = async () => {
      try {
        const userAgent = navigator.userAgent;
        const isMobile = /Mobi|Android/i.test(userAgent);
        const jwtToken = getCookie('jwtToken'); // Extragerea JWT-ului din cookie

        // Log-ul trimis către backend
        const log = {
          route: location.pathname,
          queryParams: location.search,
          userAgent,
          isMobile,
          traceId,
          jwtToken: jwtToken || '', // Dacă nu există, trimitem null
          clientIp: '',
          timestamp: new Date().toISOString(),
        };

        //Trimiterea log-ului către backend

        await axios.post(BASE_URL, log, {
            headers: {
              'Content-Type': 'application/json'
            },
          })
        
      } catch (error) {
        console.error('Eroare la trimiterea log-urilor:', error);
      }
    };

    logData();
  }, [location, traceId]);

  return null;
};

export default RouteLogger;