// src/utils/Utility.js

class Utility {
  static ConvertEstimatedValue(value) {
      if (value == null) {
          return "N.AA";
      }
      return value.toLocaleString('ro-RO', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
      });
  }

  // Conversie dată într-un format elegant
  static FormatDate(dateString) {
      if (!dateString) {
          return "N.AA";
      }

      const date = new Date(dateString);
      if (isNaN(date)) {
          return "N.AA";
      }

      const options = {
          day: '2-digit',
          month: 'long',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          hour12: false // Format 24 de ore
      };

      const formattedDate = date.toLocaleString('ro-RO', options);
      return formattedDate.replace(',', ' Ora');
  }

  static FormatDateLight(dateString) {
      if (!dateString) {
          return "N.AA";
      }

      const date = new Date(dateString);
      if (isNaN(date)) {
          return "N.AA";
      }

      const options = {
          day: '2-digit',
          month: 'long',
          year: 'numeric',
      };

      const formattedDate = date.toLocaleDateString('ro-RO', options);
      return formattedDate;
  }
}

export default Utility;
