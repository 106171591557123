import React, { useState } from "react";

const CookiesPolicy = () => {
  // Verificăm dacă utilizatorul a acceptat deja politica de cookies
  const [isOpen, setIsOpen] = useState(() => {
    // Încercăm să citim starea acceptării din localStorage
    const isAccepted = localStorage.getItem("cookiesAccepted");
    // Dacă `cookiesAccepted` nu este în localStorage, înseamnă că fereastra trebuie să fie afișată
    return !isAccepted;
  });

  const handleClose = () => {
    setIsOpen(false);
    // Salvăm acceptarea în localStorage pentru a nu mai afișa dialogul
    localStorage.setItem("cookiesAccepted", "true");
  };

  return (
    <>
      {isOpen && (
        <dialog
          className="fixed bottom-0 left-0 right-0 z-10 p-4 bg-white border-t-2 border-gray-300"
          open
        >
          <div className="flex justify-between items-start">
            <h1 className="font-bold text-lg mb-2">
              This website uses cookies to ensure you get the best experience on
              our website.
            </h1>
            <button onClick={handleClose} className="text-xl font-semibold">
              &times;
            </button>
          </div>
          <p>
            Distributed and selected partners use cookies and similar
            technologies to ensure you get the best experience on this website.
            If you consent to it, we will use cookies for analytics and
            marketing purposes.
          </p>
          <p>
            See our{" "}
            <a
              href="https://careers.distributed.com/cookie-policy"
              className="text-blue-500"
            >
              Cookie Policy
            </a>{" "}
            to read more about the cookies we set.
          </p>
          <p>
            You can withdraw and manage your consent at any time, by clicking
            "Manage cookies" at the bottom of each website page.
          </p>
          <div className="mt-4 flex gap-4">
            <button
              onClick={handleClose}
              className="px-4 py-2 rounded bg-blue-500 text-white"
            >
              Accept all cookies
            </button>
            <button
              onClick={handleClose}
              className="px-4 py-2 rounded border border-gray-300"
            >
              Disable non-necessary cookies
            </button>
          </div>
        </dialog>
      )}
    </>
  );
};

export default CookiesPolicy;
