import React from 'react';
import { useNavigate } from 'react-router-dom';
import './AuctionItem.css';
import { format } from 'date-fns';
import Utility from './../../utils/Utility';

const AuctionItem = ({ auction }) => {
  const navigate = useNavigate();

  // Formatăm data pentru a fi mai elegantă (ex: 12 Aug 2024)
  const formattedDate = format(new Date(auction.publishedDate), 'dd MMM yyyy');

  // Funcție pentru navigare la clic
  const handleNavigation = () => {
    navigate(`/licitatie/${auction.auctionId}`);
  };

  return (
    <div className="auction-item" onClick={handleNavigation}>
      <div className="auction-header">
        <h2 className="auction-id">#{auction.auctionId}</h2>
      </div>

      <div className="auction-details">
        <h3 className="auction-title">{auction.auctionTitle}</h3>
        <p className="auction-authority">
          {auction.city && auction.city !== 'Undefined' ? auction.city : 'Locație necunoscută'}, {auction.authorityName}
        </p>
      </div>

      <div className="auction-footer">
        <p className="auction-value">
          <span>Valoare estimată:</span> {Utility.ConvertEstimatedValue(auction.estimatedValue)} {auction.currency}
        </p>
        <p className="auction-date">
          <span>Publicată la:</span> {formattedDate}
        </p>
      </div>
    </div>
  );
};

export default AuctionItem;
