import React, { useState, useEffect } from 'react';
import AuctionItem from '../AuctionItem/AuctionItem';
import './AuctionList.css';
import Spinner from '../Spinner/Spinner'; // Spinner-ul, pe care îl vom crea

const AuctionList = ({ auctions, totalResults, pageSize, pageIndex, onPageChange, loading }) => {
  const [inputPage, setInputPage] = useState(pageIndex + 1); // Gestionăm valoarea pentru inputul de pagină

  const totalPages = Math.ceil(totalResults / pageSize);

  const handleNextPage = () => {
    if (pageIndex < totalPages - 1) {
      onPageChange(pageIndex + 1);
    }
  };

  const handlePrevPage = () => {
    if (pageIndex > 0) {
      onPageChange(pageIndex - 1);
    }
  };

  const handlePageSelectChange = (e) => {
    const selectedPage = parseInt(e.target.value, 10) - 1;
    setInputPage(selectedPage + 1);
    onPageChange(selectedPage);
  };

  const handlePageInput = (e) => {
    const value = e.target.value;
    if (value >= 1 && value <= totalPages) {
      setInputPage(value);
    }
  };

  const handleInputBlur = () => {
    const page = Math.max(1, Math.min(inputPage, totalPages)) - 1;
    onPageChange(page);
  };

  return (
    <div className="auction-list">
      {loading ? (
        <div className="loading-container">
          <Spinner /> {/* Spinner-ul afișat în timpul încărcării */}
          <p>Se încarcă licitațiile...</p>
        </div>
      ) : (
        <>
          {auctions.map((auction, index) => (
            <React.Fragment key={index}>
              <div className="loading-placeholder">
                <AuctionItem auction={auction} />
              </div>
              <hr className="divide-black my-4" />
            </React.Fragment>
          ))}

          <div className="pagination">
            <button onClick={handlePrevPage} disabled={pageIndex === 0}>
              &laquo; Pagina Anterioară
            </button>

            {/* Input pentru selectarea paginii */}
            <span>
              Pagina {inputPage} din {totalPages} &nbsp;
              <input
                type="number"
                value={inputPage}
                onChange={handlePageInput}
                onBlur={handleInputBlur}
                min="1"
                max={totalPages}
              />
            </span>

            <button onClick={handleNextPage} disabled={pageIndex === totalPages - 1}>
              Pagina Următoare &raquo;
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default AuctionList;
