import axios from 'axios';
import {
  GeneralInfo,
  StickyData,
  CNoticeView,
  ArchiveStatus,
  Section1View,
  Section21View,
  Section3View,
  Section4View,
  Section6View,
  DfNoticeSectionFiles,
} from '../models/CN/models';

const BASE_URL = process.env.REACT_APP_BASE_URL;
const SEARCH_API_URL = `${BASE_URL}/api/ContractListWeb/SearchNoticesAsync`;
const NOTICE_DETAILS_API_URL = `${BASE_URL}/api/ContractListWeb/GetNoticeDetail`;

/**
 * Obține rezultatele căutării utilizând filtre curate
 * @param {Object} filters - Filtrele de căutare trimise la API
 * @returns {Promise<Object>} - Rezultatele căutării
 */
export const fetchSearchResults = async (filters) => {
  console.log('Filtre trimise la API:', filters);

  // Curățarea filtrelor pentru a preveni trimiterea de date invalide
  const cleanedFilters = {
    ...filters,
    publishedDate: filters.publishedDate || null,
    minEstimatedValue: filters.minEstimatedValue
      ? parseFloat(filters.minEstimatedValue)
      : null,
    maxEstimatedValue: filters.maxEstimatedValue
      ? parseFloat(filters.maxEstimatedValue)
      : null,
    auctionId: filters.auctionId || null,
    auctionTitle: filters.auctionTitle || null,
    cpvCode: filters.cpvCode || null,
    authorityName: filters.authorityName || null,
    authorityCUI: filters.authorityCUI || null,
    city: filters.city || null,
    county: filters.county || null,
    currency: filters.currency || null,
  };

  try {
    // Apelul API pentru căutare
    const response = await axios.post(SEARCH_API_URL, cleanedFilters, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  } catch (error) {
    console.error(
      'A apărut o problemă cu operația de căutare:',
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};

/**
 * Obține detalii despre o notificare specifică după numărul acesteia
 * @param {string} noticeNo - Numărul unic al notificării
 * @returns {Promise<Object>} - Detalii despre notificare mapate
 */
export const fetchNoticeDetails = async (noticeNo) => {
  if (!noticeNo) {
    throw new Error('Numărul notificării este obligatoriu pentru obținerea detaliilor.');
  }

  try {
    const { data } = await axios.get(`${NOTICE_DETAILS_API_URL}/${noticeNo}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    // Maparea răspunsului API în modele
    return {
      generalInfo: data.GeneralInfo ? new GeneralInfo(data.GeneralInfo) : null,
      stickyData: data.StickyData ? new StickyData(data.StickyData) : null,
      cNoticeView: data.CNoticeView ? new CNoticeView(data.CNoticeView) : null,
      archiveStatus: data.ArchiveStatus ? new ArchiveStatus(data.ArchiveStatus) : null,
      section1View: data.Section1View ? new Section1View(data.Section1View) : null,
      section21View: data.Section21View ? new Section21View(data.Section21View) : null,
      section3View: data.Section3View ? new Section3View(data.Section3View) : null,
      section4View: data.Section4View ? new Section4View(data.Section4View) : null,
      section6View: data.Section6View ? new Section6View(data.Section6View) : null,
      dfNoticeSectionFiles: data.DfNoticeSectionFiles
        ? new DfNoticeSectionFiles(data.DfNoticeSectionFiles)
        : null,
    };
  } catch (error) {
    console.error(
      'Eroare la obținerea detaliilor notificării:',
      error.response?.data || error.message
    );
    throw error;
  }
};