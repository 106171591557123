import React from 'react';
import Header from './components/Header/Header';
import Licitatii from './pages/Licitatii/Licitatii';
import AuctionDetails from './pages/AuctionDetails/AuctionDetails';
import Footer from './components/Footer/Footer';
import NotFoundComponent from './components/NotFoundComponent';
import CookiesPolicy from './components/CookiesPolicy';
import Home from './pages/Home/Home';
import Subscriptions from './pages/Subscriptions/Subscriptions';
import Login from './pages/Login/Login';
import Register from './pages/Register/Register';
import UserAccount from './pages/UserAccount/UserAccount';
import RouteLogger from './components/RouteLogger';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

const App = () => {
  return (
    <HelmetProvider>
      <Router>
        <RouteLogger />
        <Header />
        <br />
        <br />
        <main className="container container-fluid">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/licitatii/" element={<Licitatii />} />
            <Route path="/abonamente/" element={<Subscriptions />} />
            <Route path="/login/" element={<Login />} />
            <Route path="/register/" element={<Register />} />
            <Route path="/licitatie/:noticeNo" element={<AuctionDetails />} />
            <Route path="/account" element={<UserAccount />} />
            <Route path="/404" element={<NotFoundComponent />} />
            <Route path="*" element={<NotFoundComponent />} />
          </Routes>
          <div className="p-4">
            <CookiesPolicy />
          </div>
        </main>
        <Footer />
      </Router>
    </HelmetProvider>
  );
};

export default App;
