import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { loginUser } from '../../services/accountService';
import './Login.css';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { success, error } = await loginUser({ email, password }, navigate);

      if (success) {
        navigate('/account');
      } else {
        setError(error || 'Invalid credentials.');
      }
    } catch (err) {
      setError(err.message || 'An unexpected error occurred.');
    }
  };

  return (
    <div className="login-container">
      <div className="login-card">
        <h2 className="login-title">Bine ai venit!</h2>
        <p className="login-subtitle">Autentifică-te pentru a continua</p>
        {error && <p className="login-error">{error}</p>}
        <form onSubmit={handleSubmit} className="login-form">
          <div className="input-group">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Introdu adresa de email"
              className="login-input"
              required
            />
          </div>
          <div className="input-group">
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Introdu parola"
              className="login-input"
              required
            />
          </div>
          <div className="forgot-password">
            <a href="#" className="forgot-password-link">Am uitat parola?</a>
          </div>
          <button type="submit" className="login-button">Autentificare</button>
        </form>
        <div className="divider">
          <span className="line"></span>
          <span className="divider-text">sau</span>
          <span className="line"></span>
        </div>
        <div className="create-account">
          <p className="create-account-text">
            Nu ai un cont?{' '}
            <a href="/register" className="create-account-link">Creează cont nou</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
