import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";
import Cookies from 'js-cookie';
import './Header.css';
import logo from '../../assets/logo.png';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [userName, setUserName] = useState(null);
  const navigate = useNavigate();

  // Verifică autentificarea la fiecare montare a componentei
  useEffect(() => {
    const token = Cookies.get('jwtToken');
    if (token) {
      try {
        const decoded = jwtDecode(token);
        setUserName(decoded.Name || 'User'); // Se folosește "name" din payload
      } catch (error) {
        console.error('Eroare la decodificarea JWT:', error);
        setUserName(null);
      }
    }
  }, []);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  const handleLogout = () => {
    Cookies.remove('jwtToken');
    setUserName(null);
    setIsDropdownOpen(false);
    navigate('/login');
  };

  const handleGoToAccount = () => {
    setIsDropdownOpen(false);
    navigate('/account');
  };

  return (
    <header className="header">
      <nav className="bg-white border-gray-200 py-2.5 dark:bg-gray-900">
        <div className="flex justify-between items-center max-w-screen-xl px-4 mx-auto">
          {/* Logo */}
          <div className="logo-container">
            <Link to="/">
              <img src={logo} alt="Logo" className="logo" />
            </Link>
          </div>

          {/* Menu */}
          <div className={`menu-container ${isMenuOpen ? 'menu-open' : ''}`}>
            <Link to="/" className="menu-link">
              Acasa
            </Link>
            <Link to="/licitatii" className="menu-link">
              Licitații
            </Link>
            <Link to="/proiecte" className="menu-link">
              Proiecte
            </Link>
            <Link to="/abonamente" className="menu-link">
              Abonamente
            </Link>
            <Link to="/blog" className="menu-link">
              Blog
            </Link>
            <Link to="/contact" className="menu-link">
              Contact
            </Link>
          </div>

          {/* Buttons */}
          <div className="buttons-container">
            {userName ? (
              <div className="dropdown-container">
                <button className="dropdown-button" onClick={toggleDropdown}>
                  {userName}
                  <svg
                    className="dropdown-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={2}
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </button>
                {isDropdownOpen && (
                  <div className="dropdown-menu">
                    <button onClick={handleGoToAccount} className="dropdown-item">
                      Account
                    </button>
                    <button onClick={handleLogout} className="dropdown-item">
                      Logout
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <Link to="/login" className="text-gray-800 dark:text-white hover:bg-gray-50 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 sm:mr-2 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800">
                Login
              </Link>
            )}
            <a
              href="#"
              className="text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 sm:mr-2 lg:mr-0 dark:bg-purple-600 dark:hover:bg-purple-700 focus:outline-none dark:focus:ring-purple-800"
            >
              Call an Expert
            </a>
          </div>

          {/* Hamburger Menu */}
          <button className="hamburger" onClick={toggleMenu}>
            <svg
              className="menu-icon"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4 6h16M4 12h16m-7 6h7"
              />
            </svg>
          </button>
        </div>

        {/* Mobile Dropdown */}
        {isMenuOpen && (
          <div className="mobile-menu">
            <Link to="/" className="mobile-link" onClick={toggleMenu}>
              Acasa
            </Link>
            <Link to="/licitatii" className="mobile-link" onClick={toggleMenu}>
              Licitații
            </Link>
            <Link to="/proiecte" className="mobile-link" onClick={toggleMenu}>
              Proiecte
            </Link>
            <Link to="/abonamente" className="mobile-link" onClick={toggleMenu}>
              Abonamente
            </Link>
            <Link to="/blog" className="mobile-link" onClick={toggleMenu}>
              Blog
            </Link>
            <Link to="/contact" className="mobile-link" onClick={toggleMenu}>
              Contact
            </Link>
          </div>
        )}
      </nav>
    </header>
  );
};

export default Header;
