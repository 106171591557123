import axios from 'axios';
import Cookies from 'js-cookie';

const BASE_URL = process.env.REACT_APP_BASE_URL;
const LOGIN_API_URL = `${BASE_URL}/api/Account/login`;
const REGISTER_API_URL = `${BASE_URL}/api/Account/register`;
const USER_INFO_API_URL = `${BASE_URL}/api/Account/userInfo`;
const UPDATE_COMPANY_INFO_API_URL = `${BASE_URL}/api/Account/UpdateCompanyInfo`;
const UPDATE_CONTACT_INFO_API_URL = `${BASE_URL}/api/Account/UpdateContactInfo`;
const CHANGE_PASSWORD_API_URL = `${BASE_URL}/api/Account/ChangePassword`;

/**
 * Autentificare utilizator
 * @param {Object} credentials - Credențialele utilizatorului (email și parolă)
 * @param {Function} navigate - Funcția de navigare pentru redirect
 * @returns {Promise<Object>} - Obiect cu succesul autentificării și eventuale erori
 */
export const loginUser = async (credentials, navigate) => {
  if (!credentials.email || !credentials.password) {
    throw new Error('Email-ul și parola sunt obligatorii.');
  }

  try {
    const response = await axios.post(LOGIN_API_URL, credentials, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const { success, token, error } = response.data;

    if (success) {
      // Salvare token în cookie
      Cookies.set('jwtToken', token, {
        expires: 7,
        secure: window.location.protocol === 'https:',
        sameSite: 'Strict',
      });

      // Redirect către prima pagină
      navigate('/');
    }

    return { success, error };
  } catch (error) {
    console.error(
      'Eroare la autentificarea utilizatorului:',
      error.response?.data || error.message
    );

    return {
      success: false,
      error: error.response?.data?.error || 'A apărut o eroare.',
    };
  }
};

/**
 * Verifică dacă utilizatorul este autentificat
 * @returns {boolean} - True dacă există un token valid
 */
export const isAuthenticated = () => {
  const token = Cookies.get('jwtToken');
  return !!token;
};

/**
 * Logout utilizator
 * @returns {void}
 */
export const logoutUser = () => {
  Cookies.remove('jwtToken');
};

/**
 * Înregistrare utilizator
 * @param {Object} userData - Datele utilizatorului pentru înregistrare
 * @returns {Promise<Object>} - Obiect cu succesul înregistrării și eventuale erori
 */
export const registerUser = async (userData) => {
  try {
    const response = await axios.post(REGISTER_API_URL, userData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const { success, error } = response.data;

    return { success, error };
  } catch (error) {
    console.error(
      'Eroare la înregistrarea utilizatorului:',
      error.response?.data || error.message
    );

    return {
      success: false,
      error: error.response?.data?.error || 'A apărut o eroare.',
    };
  }
};

/**
 * Obține informațiile utilizatorului din backend
 * @returns {Promise<Object>} - Obiect cu Success, Error și User
 */
export const getUserInfo = async () => {
  const token = Cookies.get('jwtToken');
  if (!token) throw new Error('Utilizatorul nu este autentificat.');

  try {
    const response = await axios.get(USER_INFO_API_URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data; // Returnează întregul răspuns standardizat
  } catch (error) {
    console.error('Eroare la obținerea informațiilor utilizatorului:', error.response?.data || error.message);
    return {
      Success: false,
      Error: error.response?.data?.Error || 'A apărut o eroare.',
      User: null,
    };
  }
};

/**
 * Actualizează informațiile companiei
 * @param {Object} companyData - Datele companiei
 * @returns {Promise<boolean>} - Succes sau eroare
 */
export const updateUserCompanyInfo = async (companyData) => {
  try {
    const token = Cookies.get("jwtToken");
    const response = await axios.post(
      UPDATE_COMPANY_INFO_API_URL,
      companyData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    return response.data.success;
  } catch (error) {
    console.error("Eroare la actualizarea informațiilor companiei:", error.message);
    return false;
  }
};

/**
 * Actualizează informațiile de contact
 * @param {Object} contactData - Datele de contact
 * @returns {Promise<boolean>} - Succes sau eroare
 */
export const updateUserContactInfo = async (contactData) => {
  try {
    const token = Cookies.get("jwtToken");
    const response = await axios.post(
      UPDATE_CONTACT_INFO_API_URL,
      contactData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    return response.data.success;
  } catch (error) {
    console.error("Eroare la actualizarea informațiilor de contact:", error.message);
    return false;
  }
};

/**
 * Schimbă parola utilizatorului
 * @param {Object} passwordData - Obiect care conține parola nouă
 * @returns {Promise<boolean>} - True dacă parola a fost schimbată cu succes
 */
export const changeUserPassword = async (passwordData) => {
  try {
    const response = await axios.post(CHANGE_PASSWORD_API_URL, passwordData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${Cookies.get('jwtToken')}`, // Include token-ul JWT pentru autentificare
      },
    });

    const { success, error } = response.data;

    if (!success) {
      throw new Error(error || 'A apărut o eroare la schimbarea parolei.');
    }

    return true; // Succes
  } catch (error) {
    console.error(
      'Eroare la schimbarea parolei utilizatorului:',
      error.response?.data || error.message
    );
    return false; // Eșec
  }
};