import React, { useState, useEffect } from "react";
import svg_ro from "./../../assets/ro.svg";
import { parse } from "svgson"; // Instalează svgson: `npm install svgson`

const RomaniaMap = () => {
  const [counties, setCounties] = useState([]); // Lista județelor
  const [selectedCounty, setSelectedCounty] = useState(null); // Județul selectat

  useEffect(() => {
    // Extragem datele din fișierul SVG
    fetch(svg_ro)
      .then((response) => response.text())
      .then((svgText) => parse(svgText))
      .then((parsedSvg) => {
        const paths = parsedSvg.children.find((child) => child.name === "g");
        if (paths) {
          const countyPaths = paths.children
            .filter((child) => child.name === "path")
            .map((path) => ({
              id: path.attributes.id || "",
              d: path.attributes.d || "",
              name: path.attributes["data-name"] || "",
            }));
          setCounties(countyPaths);
        }
      })
      .catch((error) =>
        console.error("Eroare la extragerea datelor din SVG:", error)
      );
  }, []);

  const handleCountyClick = (county) => {
    setSelectedCounty(county); // Actualizăm județul selectat
  };

  return (
    <div style={{ textAlign: "center", marginTop: "20px" }}>
      <h1>Harta Județelor României</h1>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1000 704"
          width="80%"
          height="80%"
        >
          {counties.map((county) => (
            <path
              key={county.id}
              d={county.d}
              data-name={county.name}
              onClick={() => handleCountyClick(county)}
              style={{
                fill:
                  selectedCounty?.id === county.id
                    ? "rgb(231, 76, 60)" // Roșu pentru județul selectat
                    : "rgb(52, 152, 219)", // Albastru pentru celelalte județe
                cursor: "pointer",
                transition: "fill 0.2s",
              }}
              onMouseOver={(e) => {
                if (selectedCounty?.id !== county.id) {
                  e.target.style.fill = "#6c5ce7"; // Mov la hover pentru județe ne-selectate
                }
              }}
              onMouseOut={(e) => {
                if (selectedCounty?.id !== county.id) {
                  e.target.style.fill = "rgb(52, 152, 219)"; // Revine la albastru
                }
              }}
            />
          ))}
        </svg>
      </div>
      <div
        style={{
          marginTop: "20px",
          padding: "10px",
          border: "1px solid #ccc",
          borderRadius: "5px",
          display: "inline-block",
        }}
      >
        <h2>Detalii despre județul selectat</h2>
        <p>
          {selectedCounty
            ? `Județ selectat: ${selectedCounty.id}`
            : "Selectează un județ pentru a vedea detalii."}
        </p>
      </div>
    </div>
  );
};

export default RomaniaMap;
