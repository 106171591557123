import React, { useState } from 'react';
import './PolicyModal.css';

const PolicyModal = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <button className="policy-button" onClick={() => setIsOpen(true)}>Politica de cookie</button>
      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50" onClick={() => setIsOpen(false)}>
          <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md mx-auto" onClick={(e) => e.stopPropagation()}>
            <span className="close text-gray-600 text-xl text-black" onClick={() => setIsOpen(false)}>&times;</span>
            <h2 className="text-xl font-bold mb-4 text-black">Politica de Cookie</h2>
            <p className="text-black">
              Această politică de cookie explică ce sunt cookie-urile și cum le folosim pe platforma ${process.env.REACT_APP_NAME}. Te rugăm să citești cu atenție această politică pentru a înțelege ce tipuri de cookie-uri utilizăm, informațiile pe care le colectăm folosindu-le și cum sunt utilizate aceste informații.
            </p>
            <p className="text-black">
              <strong>Ce sunt cookie-urile?</strong> Cookie-urile sunt fișiere mici de text stocate pe dispozitivul tău atunci când vizitezi un site web. Acestea permit site-ului să rețină anumite informații despre vizita ta, cum ar fi preferințele sau activitatea anterioară.
            </p>
            <p className="text-black">
              <strong>Tipuri de cookie-uri pe care le folosim:</strong> 
              <ul className="list-disc pl-6">
                <li>Cookie-uri necesare pentru funcționarea platformei.</li>
                <li>Cookie-uri de performanță pentru a îmbunătăți experiența de navigare.</li>
                <li>Cookie-uri de funcționalitate pentru a reține preferințele utilizatorilor.</li>
                <li>Cookie-uri de marketing pentru anunțuri relevante.</li>
              </ul>
            </p>
            <p className="text-black">
              Poți controla cookie-urile prin setările browserului. Pentru mai multe informații, vizitează <a href="https://www.allaboutcookies.org" target="_blank" rel="noopener noreferrer">allaboutcookies.org</a>.
            </p>
            <button className="close-modal-button" onClick={() => setIsOpen(false)}>Close</button>

          </div>
        </div>
      )}
    </>
  );
};

export default PolicyModal;
