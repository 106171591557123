class GeneralInfo {
  constructor(data) {
      this.noticeId = data.NoticeId;
      this.sysNoticeState = data.SysNoticeState?.Text;
      this.dfNoticeId = data.DfNoticeId;
      this.dfNoticeNumber = data.DfNoticeNumber;
      this.sysNoticeVersionDf = data.SysNoticeVersionDf?.Text;
      this.dfNoticeDate = data.DfNoticeDate;
      this.planName = data.PlanName;
      this.planDetailName = data.PlanDetailName;
      this.spentValue = data.SpentValue;
      this.currency = data.Currency;
  }
}

class StickyData {
  constructor(data) {
      this.noticeId = data.NoticeId;
      this.noticeNumber = data.NoticeNumber;
      this.contractTitle = data.ContractTitle;
      this.sysNoticeType = data.SysNoticeType?.Text;
      this.isUtility = data.IsUtility;
      this.sysLegislationType = data.SysLegislationType?.Text;
      this.publicationDate = data.PublicationDate;
      this.caName = data.CaName;
      this.caCif = data.CaCif;
      this.sysProcedureType = data.SysProcedureType?.Text;
  }
}

class CNoticeView {
  constructor(data) {
      this.cNoticeId = data.CNoticeId;
      this.noticeId = data.NoticeId;
      this.noticeNo = data.NoticeNo;
      this.cpvCodeAndName = data.CpvCodeAndName;
      this.tenderReceiptDeadline = data.TenderReceiptDeadline;
      this.contractTitle = data.ContractTitle;
      this.sysContractAssignmentType = data.SysContractAssigmentType?.Text;
      this.dfDocsAndClarificationsZipUrl = data.DfDocsAndClarificationsZipUrl;
  }
}

class ArchiveStatus {
  constructor(data) {
      this.archiveItem = data.ArchiveItem;
      this.hasError = data.HasError;
      this.responseMessage = data.ResponseMessage;
  }
}

class Section1View {
  constructor(data) {
      this.noticeEntityAddress = data.NoticeEntityAddress;
      this.isCommonProcurement = data.IsCommonProcurement;
      this.commonProcurementLaw = data.CommonProcurementLaw;
      this.onBehalfOfOtherCa = data.OnBehalfOfOtherCa;
      this.hasError = data.HasError;
      this.responseMessage = data.ResponseMessage;
  }
}

class Section21View {
  constructor(data) {
    this.title = data.Title;
    this.referenceNumber = data.ReferenceNumber;
    this.mainCpvCode = data.MainCpvCode;
    this.estimatedValue = data.EstimatedValue;
    this.currencyCode = data.CurrencyCode;
    this.monthDuration = data.MonthDuration;
    this.isRenewableContract = data.IsRenewableContract;
    this.renewalDescription = data.RenewalDescription;
    this.additionalInfo = data.AdditionalInfo;
  }
}

class Section3View {
  constructor(data) {
      this.personalSituation = data.PersonalSituation;
      this.criteriaQAStandard = data.TpCriteriaQAStandard;
      this.prCriteria = data.PrCriteria;
      this.depositsAndWarranties = data.DepositsAndWarranties;
      this.mainFinancialMethods = data.MainFinancialMethods;
      this.efCriteriaMin = data.EfCriteriaMin;
      this.tpCriteriaQAStandardMin = data.TpCriteriaQAStandardMin;
  }
}

class Section4View {
  constructor(data) {
      this.sysProcedureTypeName = data.SysProcedureTypeName;
      this.isFrameworkAgreement = data.IsFrameworkAgreement;
      this.languages = data.Languages;
      this.tenderReceiptDeadline = data.TenderReceiptDeadline;
      this.tenderOpeningDate = data.TenderOpeningDate;
      this.tenderOpeningPlace = data.TenderOpeningPlace;
      this.tenderAvailabilityDeadline = data.TenderAvailabilityDeadline;
      this.hasError = data.HasError;
      this.responseMessage = data.ResponseMessage;
  }
}

class Section6View {
  constructor(data) {
      this.isRecurrentProcurement = data.IsRecurrentProcurment;
      this.additionalInformation = data.AdditionalInformation;
      this.hasError = data.HasError;
      this.responseMessage = data.ResponseMessage;
  }
}

class DfNoticeSectionFiles {
  constructor(data) {
      this.dfNoticeDocs = data.DfNoticeDocs.map(doc => new NoticeDocument(doc));
      this.duaeDocs = data.DuaeDocs.map(doc => new NoticeDocument(doc));
  }
}

class NoticeDocument {
  constructor(data) {
      this.noticeDocumentUrl = data.NoticeDocumentUrl;
      this.noticeDocumentName = data.NoticeDocumentName;
      this.noticeDocumentCode = data.NoticeDocumentCode;
  }
}

class BodyForComplainSolution {
  constructor(data) {
      this.officialName = data.OfficialName;
      this.address = data.Address;
      this.postalCode = data.PostalCode;
      this.email = data.Email;
      this.phone = data.Phone;
      this.fax = data.Fax;
  }
}

export {
  GeneralInfo,
  StickyData,
  CNoticeView,
  ArchiveStatus,
  Section1View,
  Section21View,
  Section3View,
  Section4View,
  Section6View,
  DfNoticeSectionFiles,
  NoticeDocument,
  BodyForComplainSolution
};
